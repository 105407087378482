import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.toggleStatus = false;
  }

  toggle(event) {
    this.toggleStatus = !this.toggleStatus
    this.element.querySelectorAll('input[type=checkbox]').forEach(checkbox => {
      checkbox.checked = this.toggleStatus
    });
  }
}

import { Controller } from '@hotwired/stimulus'
import jQuery from 'jquery'
import 'jquery-mask-plugin/dist/jquery.mask.min'

export default class extends Controller {
  static values = {
    precision: { type: Number, default: 4 }
  }

  connect() {
     let mask = '#.##0'
     if (this.precisionValue > 0) {
      mask += ','
      mask += '0'.repeat(this.precisionValue);
     }

    jQuery(this.element).mask(mask, { reverse: true })
  }
}

import { Controller } from '@hotwired/stimulus';

import 'select2';
import 'select2/dist/js/i18n/pt-BR';

import { getMetaValue } from './helpers';

export default class extends Controller {
  static targets = ['field'];

  connect() {
    this.initField();
    this.focusField();
  }

  clear() {
    jQuery(this.fieldTarget).val(null).trigger('change');
  }

  initField() {
    jQuery(this.fieldTarget).select2({
      theme: 'bootstrap-5',
      language: 'pt-BR',
      templateResult: this.templateResult,
      templateSelection: this.templateSelection,
      ajax: {
        url: `/cities.json`,
        dataType: 'json',
        headers: {
          'X-CSRF-Token': getMetaValue('csrf-token')
        },
        cache: true,
        data: params => ({ filter: { search: params.term }}),
        processResults: (data, params) => {
          const results = data.cities.map(city => ({ id: city.id, text: `${city.name}-${city.state.acronym}` }));
          return { results: results };
        }
      }
    });
  }

  focusField() {
    jQuery(document).on('select2:open', () => {
      document.querySelector('.select2-search__field').focus();
    });
  }
}

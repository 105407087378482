import { Controller } from '@hotwired/stimulus'
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  connect() {
    setTimeout(() => {
      new bootstrap.Tooltip(this.element)
    }, 500)
  }
}

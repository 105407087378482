import { Application } from "@hotwired/stimulus"
import Sortable from "stimulus-sortable"
import RailsNestedForm from "@stimulus-components/rails-nested-form/dist/stimulus-rails-nested-form.umd.js"

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

// Plugins
application.register("sortable", Sortable)
application.register("nested-form", RailsNestedForm)

export { application }

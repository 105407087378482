// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import { Turbo } from "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "@fortawesome/fontawesome-free/css/all";
import * as bootstrap from "bootstrap";
import "jquery";

import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';


// DayJS
dayjs.extend(customParseFormat);
dayjs.locale('pt-br');

// Import images
require.context("../images", true)

// This is the line we're adding
import "stylesheets/application"

Rails.start()
ActiveStorage.start()

import "controllers"

import { Controller } from '@hotwired/stimulus';

import 'select2';
import 'select2/dist/js/i18n/pt-BR';

export default class extends Controller {
  static targets = ['field'];
  static values = { placeholder: String, options: Object };

  connect() {
    this.initField();
    this.focusField();
  }

  clear() {
    jQuery(this.fieldTarget).val(null).trigger('change');
  }

  initField() {
    const customOptions = this.optionsValue;
    const options = Object.assign({
      placeholder: this.placeholderValue,
      theme: 'bootstrap-5',
      language: 'pt-BR'
    }, customOptions);


    jQuery(this.fieldTarget).select2(options);
  }

  focusField() {
    jQuery(document).on('select2:open', () => {
      document.querySelector('.select2-search__field').focus();
    });
  }
}
